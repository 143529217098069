import { client, Query, ContentTypes, ResponseData } from '../galaxyClient';

export type GetLocationsResponse = ResponseData<ContentTypes, '/v2/locations/', 'get'>;
type GetLocationQuery = Query<ContentTypes, '/v2/locations/', 'get'>;

export const getLocations = async ({ query = {} }: { query: GetLocationQuery }) => {
  const { data } = await client().content.GET(`/v2/locations/`, { params: { query } });

  return data;
};

export const getLocationsChunked = async ({
  query = {},
  chunkKey,
}: {
  query: GetLocationQuery;
  chunkKey: keyof GetLocationQuery;
}) => {
  const { pages } = await client().content.GET_CHUNKED(`/v2/locations/`, {
    params: { query },
    chunkKey,
  });

  return pages.flatMap((page) => page.result);
};
